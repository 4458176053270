import React from 'react'
import {graphql} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

import {Subtitle} from '../../../elements'
import {Layout, MetaData} from '../../../components'
import {
  ThemePageHeader,
  ThemeFeatureCard,
  FeatureCards,
  Features,
  FeaturesList,
  TwoColumnSection,
} from '../../../components/ThemePage'
import CTA, {CTAItem} from '../../../components/CTA'
import {ShowcaseSectionMinimal} from '../../../components/showcase'

import MembershipIcon from '../../../images/inline-svgs/online-payment.svg'
import GhostIcon from '../../../images/inline-svgs/ghost.svg'
import DocumentationIcon from '../../../images/inline-svgs/documents.svg'
import DownloadIcon from '../../../images/inline-svgs/download.svg'
import ResponsiveIcon from '../../../images/inline-svgs/responsive.svg'
import DarkModeIcon from '../../../images/inline-svgs/day-and-night.svg'
import CustomizableIcon from '../../../images/inline-svgs/customizable.svg'
import TranslateIcon from '../../../images/inline-svgs/translate.svg'
import theme from '../../../styles/theme'

const SapphireGhostTheme = ({data}) => {
  const showcasePosts = []
  showcasePosts.push(data.showcasePost1, data.showcasePost2, data.showcasePost3)

  return (
    <Layout>
      <MetaData data={data.currentPage} />
      <ThemePageHeader
        title="Sapphire"
        eyebrow="Ghost"
        description="A <a href='https://ghost.org' class='link--hover--underline' target='_blank' rel='noopener noreferrer'>Ghost</a> theme designed for building personal blogs, and niche publications. You can use this theme to create a blog, build premium memberships or create a portfolio of your work."
        price="$ 79"
        purchaseLink="https://gum.co/sapphire-ghost-theme"
        demoLink="https://sapphire.stylesheets.dev/"
        image={data.header.childImageSharp.gatsbyImageData}
        eyebrowLink="/themes/ghost/"
      />

      <FeatureCards>
        <ThemeFeatureCard
          title="Ghost 4.x"
          description="Supports latest version of Ghost."
        >
          <GhostIcon fill="#444" width="40px" />
        </ThemeFeatureCard>
        <ThemeFeatureCard
          title="Memberships Support"
          description="Add Ghost memberships and subscription feature to your site."
        >
          <MembershipIcon fill="#444" />
        </ThemeFeatureCard>
        <ThemeFeatureCard
          title="Reading Modes"
          description="The theme comes with Light, Dark, and Sepia reading modes."
        >
          <DarkModeIcon fill="#444" />
        </ThemeFeatureCard>
        <ThemeFeatureCard
          title="Easy to Customize"
          description="Fully customizable Fonts & Colors with CSS variables and SMACSS file organization."
        >
          <CustomizableIcon fill="#444" />
        </ThemeFeatureCard>
        <ThemeFeatureCard
          title="Fully Documented"
          description="Fully documented for setting up and making customizations on your site."
        >
          <DocumentationIcon fill="#444" />
        </ThemeFeatureCard>
        <ThemeFeatureCard
          title="Translation Ready"
          description="Theme is translation ready and already includes English and German translations. More languages can be added."
        >
          <TranslateIcon fill="#444" />
        </ThemeFeatureCard>
      </FeatureCards>

      <Features>
        <FeaturesList>
          <li>Supports latest versions of Ghost 4.0+</li>
          <li>Responsive layout</li>
          <li>Customizable typography and color palette</li>
          <li>Google fonts</li>
          <li>Ghost premium membership feature support</li>
          <li>Social media sharing icons</li>
          <li>
            Syntax highlighting with{' '}
            <a
              href="https://prismjs.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="link--inline"
            >
              PrismJs
            </a>
          </li>
          <li>
            Search (In-built services include Google Programmable Search Engine
            &amp; GhostHunter search)
          </li>
          <li>Space for integrating comments from services like Disqus etc.</li>
          <li>Posts &amp; Tags archive</li>
          <li>
            Accessibility tested for WCAG Level AA compatibility (
            <a
              href="https://wave.webaim.org/report#/https://sapphire.stylesheets.dev/"
              target="_blank"
              rel="noopener noreferrer"
              className="link--inline"
            >
              WAVE
            </a>{' '}
            report).
          </li>
          <li>Free updates</li>
        </FeaturesList>
      </Features>

      <TwoColumnSection
        backgroundColor="#000"
        color="#efefef"
        backgroundImage={data.darkModeBg}
      >
        <div>
          <h2>Light, Dark and Sepia Reading Modes</h2>
          <Subtitle margin="24px 0 0">
            Sapphire comes with Light, Dark and Sepia reading modes ready
            out-of-box.
          </Subtitle>
        </div>
        <div
          style={{
            justifySelf: 'center',
          }}
        >
          <StaticImage
            src="../../../images/sapphire/reading-modes.png"
            alt="Light, Dark and Sepia Reading Modes"
            placeholder="blurred"
            width={600}
            layout="constrained"
          />
        </div>
      </TwoColumnSection>

      <TwoColumnSection reverse>
        <div>
          <StaticImage
            src="../../../images/sapphire/customizable.png"
            alt="Fully Customizable Typography and Color Palette"
            placeholder="blurred"
            width={700}
            layout="constrained"
          />
        </div>
        <div>
          <h2>Fully Customizable Typography &amp; Color Palette</h2>
          <Subtitle margin="24px 0 0">
            All fonts and colors are centrally managed in a separate variables
            file making it easier to adapt the theme to your personality.
          </Subtitle>
        </div>
      </TwoColumnSection>

      <ShowcaseSectionMinimal
        posts={showcasePosts}
        bgColor={theme.color.background.lightgrey}
        title="What People Are Building"
        subtitle="Check out some of the sites using Sapphire theme"
      />

      <CTA title="Ready to Get Started?">
        <CTAItem
          title="Purchase Theme"
          link="https://gum.co/sapphire-ghost-theme"
          externalLink
        >
          <DownloadIcon fill="#fff" />
        </CTAItem>
        <CTAItem
          title="View Demo"
          link="https://sapphire.stylesheets.dev/"
          externalLink
        >
          <ResponsiveIcon fill="#fff" />
        </CTAItem>
        <CTAItem
          title="Documentation"
          link="https://docs.stylesheets.dev/sapphire"
          externalLink
        >
          <DocumentationIcon fill="#fff" />
        </CTAItem>
      </CTA>
    </Layout>
  )
}

export default SapphireGhostTheme

export const query = graphql`
  query {
    currentPage: ghostPage(slug: {eq: "sapphire-ghost"}) {
      ...GhostMetaPageFields
    }
    header: file(relativePath: {eq: "themes/sapphire.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    darkModeBg: file(relativePath: {eq: "sapphire/dark-mode-bg.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 80)
      }
    }
    showcasePost1: contentfulShowcasePost(
      contentful_id: {in: "3KRZZLvO0mAGUHhXruv2UW"}
    ) {
      ...ContentfulShowcasePostFields
    }
    showcasePost2: contentfulShowcasePost(
      contentful_id: {in: "2Kv3Ivk93kkR3yY5Cag1uI"}
    ) {
      ...ContentfulShowcasePostFields
    }
    showcasePost3: contentfulShowcasePost(
      contentful_id: {in: "57JNTrVKu5IMEoJPDSRLm4"}
    ) {
      ...ContentfulShowcasePostFields
    }
  }
`
